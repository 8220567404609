import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import SubTitle from "../components/SubTitle"

export const ResourcesPageTemplate = ({
  seo,
  hero,
  list
}) => {
  return (
    <div>
      <SEO title={seo.title} description={seo.description} />
      <HeroOther data={hero} />
      <section className="section">
        <div className="container">
          {
            list.map((post, i) => {
              return (
                <div className="columns" key={i}>
                  <div className="column is-10 is-offset-1">
                    <div className="columns" style={{ borderBottom: "1px solid #eee", padding: "2rem 0" }}>
                      <div className="column is-4">
                        <PreviewCompatibleImage imageInfo={post.node.frontmatter.image} />
                      </div>
                      <div className="column is-8">
                        <Link to={post.node.fields.slug}>
                          <SubTitle title={post.node.frontmatter.title} color="#53c5d1" />
                        </Link>
                        <p style={{ fontSize: "1.5rem", margin: "1rem 0" }}>
                          {post.node.frontmatter.description}
                        </p>
                        <Link to={post.node.fields.slug}>
                          <button style={{ background: "#53c5d1" }}>Read More</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </section>
    </div>
  )
}

ResourcesPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  list: PropTypes.array
}

const ResourcesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { edges } = data.allMarkdownRemark

  return (
    <Layout>
      <ResourcesPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        list={edges}
      />
    </Layout>
  )
}

ResourcesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    allMarkdownRemark: PropTypes.object
  }),
}

export default ResourcesPage

export const pageQuery = graphql`
  query ResourcesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "resources-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "resources-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
